export const DATA_LAYER_KEYS = {
  CONSENT_REQUIRED: 'consent_required',
  CONSENT_FUNCTIONAL: 'consent_functional',
  CONSENT_ADVERTISING: 'consent_advertising',
  USER_CONSENT: 'user_consent',
  USER_CONSENT_FUNCTIONAL: 'user_consent_functional',
  USER_CONSENT_ADVERTISING: 'user_consent_advertising',
  GA4_ID: 'ga4_id',
  GA4_ID_ADDITIONAL: 'ga4_id_additional',
  GOOGLE_ADS_CONVERSION_ID: 'google_ads_conversion_id',
  GOOGLE_ADS_CONVERSION_LABEL: 'google_ads_conversion_label',
  FB_PIXEL_ID: 'fb_pixel_id',
  CONFIG_ID: 'config_id',
  CONFIG_NAME: 'config_name',
  APP_TYPE: 'app_type',
} as const
