<template>
  <div id="app-entradas">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <ConsentManager />

    <ClientOnly>
      <LazyAdSlot
        v-if="isGooglePublisherTagConsentGiven"
        v-bind="appConfig.adSlots.skin"
        slot-name="skin"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
await useAsyncData('app:setup', () => useAppSetup())

const appConfig = useAppConfig()
const { getConsentForPurpose } = useConsentManager()
const isGooglePublisherTagConsentGiven = computed(() =>
  getConsentForPurpose(CONSENT_MANAGER_PURPOSE.ADVERTISING)
)

defineOptions({
  name: 'AppEntradas',
})
</script>
