// This is a workaround to fix the useHead() composable not applying the design properly.
// https://github.com/unjs/unhead/issues/357
// should be fixed with the latest nuxt version
import useDesign from '@theme/composables/use-design'

export default defineNuxtRouteMiddleware(() => {
  const { design, applyDesign } = useDesign()

  if (design.value) {
    applyDesign()
  }
})
