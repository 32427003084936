export default function useModal({
  onClose,
  initiallyOpened = false,
  classes = {},
}: {
  onClose: () => void
  initiallyOpened?: boolean
  classes?: {
    container?: string
    backdrop?: string
    modal?: string
    close?: string
  }
}) {
  const isOpen = ref(initiallyOpened)

  function close() {
    isOpen.value = false
    setTimeout(onClose, 200)
  }

  function open() {
    setTimeout(() => {
      isOpen.value = true
    }, 200)
  }

  const cssClasses = computed(() => ({
    container:
      classes.container ??
      'fixed inset-0 z-50 flex flex-col items-end justify-end sm:items-center sm:justify-center sm:p-10',
    backdrop: classes.backdrop ?? {
      'fixed inset-0 z-10 bg-black/50 backdrop-blur-[2px] transition-opacity duration-300':
        true,
      'pointer-events-none opacity-0': !isOpen.value,
      'pointer-events-auto opacity-100': isOpen.value,
    },
    modal: {
      'z-20 h-auto max-h-[90dvh] w-full max-w-4xl overflow-hidden rounded-t-lg duration-200 sm:rounded-b-lg drop-shadow-2xl':
        true,
      'translate-y-full opacity-0 sm:scale-75': !isOpen.value,
      'translate-y-0 opacity-100 delay-200 sm:scale-100': isOpen.value,
    },
    close: classes.close ?? {
      'text-light bg-neutral/50 hover:bg-neutral-dark-1 absolute right-2 top-2 z-20 rounded-full transition-colors duration-200':
        true,
    },
  }))

  return {
    isOpen,
    close,
    open,
    cssClasses,
  }
}
