import type { Link } from '@unhead/vue'
import type { CmsConfig } from '#gql/default'

export default async function useAppSetup({
  addFavicon = true,
}: { addFavicon?: boolean } = {}) {
  const config = useCmsConfig()
  const { currentUrl } = useCurrentUrl()
  const route = useRoute()
  const { locale } = useI18n()
  useGqlHeaders({ 'Accept-Language': locale.value })

  const { data } = await useAsyncGql('FetchCmsConfig', {
    url: currentUrl.value,
  })

  if (!data.value?.cmsConfig) {
    showError({
      statusCode: 404,
      statusMessage: 'CmsConfig not found',
    })
  } else {
    config.value = data.value.cmsConfig as CmsConfig
    const { design, applyDesign } = useDesign()
    design.value = data.value.cmsConfig.defaultDesign
    applyDesign()
  }

  const { fetchServerTime } = useServerTime()
  await fetchServerTime()

  const link: Link[] = [
    {
      rel: 'canonical',
      href: `https://${config.value?.defaultDomain}${route.path}`,
    },
  ]
  if (addFavicon && config.value?.favicon) {
    link.push({
      rel: 'icon',
      type: 'image/x-icon',
      href: getImage(config.value, 'favicon', config.value?.name).src as string,
    })
  }

  useHead({
    link,
    titleTemplate(title) {
      return [title, config.value?.name].filter(Boolean).join(' | ')
    },
  })
}
