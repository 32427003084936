export default function useDropdown({
  classes,
  onClose,
  style,
}: {
  onClose: () => void
  classes?: {
    container?: string | object | []
    dropdown?: string | object | []
  }
  style: DropdownStyle
}) {
  const isOpen = ref(false)

  function close() {
    isOpen.value = false
    setTimeout(onClose, 200)
  }

  function open() {
    setTimeout(() => {
      isOpen.value = true
    }, 1)
  }

  const { OFF_CANVAS, ON_PAGE } = DROPDOWN_STYLE

  const cssClasses = computed(() => ({
    container: [
      {
        'fixed inset-0 z-50 flex flex-col items-end justify-end':
          style === OFF_CANVAS,
        'md:absolute w-full': ON_PAGE,
      },
      classes?.container,
    ],
    backdrop: [
      'fixed inset-0 z-10 bg-black/50 backdrop-blur-[2px] transition-opacity duration-300',
      {
        'pointer-events-none opacity-0': !isOpen.value,
        'pointer-events-auto opacity-100': isOpen.value,
      },
    ],
    dropdown: [
      'dark:text-light light:text-dark bg-neutral-light-2 dark:bg-neutral-dark-2',
      'z-20 h-auto overflow-hidden',
      'rounded-t-lg md:rounded-b-lg py-4',
      'transform-gpu transition-transform duration-150',
      'grid-rows-[auto,minmax(0,1fr),auto] grid',
      {
        'opacity-0': !isOpen.value,
        'opacity-100': isOpen.value,
        'translate-y-full': style === OFF_CANVAS && !isOpen.value,
        'translate-y-0': style === OFF_CANVAS && isOpen.value,
        'max-h-[calc(100vh-6rem)]': style === OFF_CANVAS,
      },
      classes?.dropdown,
    ],
    header: 'flex justify-between gap-4 px-4 pb-4 empty:hidden',
    content: 'scrollbar-themed overflow-y-auto flex-grow',
    footer: 'flex justify-between gap-4 px-4 pt-4 empty:hidden',
  }))

  return {
    open,
    close,
    cssClasses,
  }
}
