import { default as _91_46_46_46pageSlug_93evc6HKhu5EMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activateRcAZKJZK6HMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/activate.vue?macro=true";
import { default as index0xTmVL2jcDMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93F4Mvt8FqE9Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newDV7gy5FGAqMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistlL87ITAot5Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/watchlist.vue?macro=true";
import { default as faqoBv6HeBWq4Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as indexiJMztY87i8Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue?macro=true";
import { default as info3o03BKc7RJMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as indexWbdlRkKif7Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue?macro=true";
import { default as _91showGroupSlug_93jjP2lej3WDMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programqOlwHDIozvMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shoptspmF1Bci0Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93TW9GltLUY0Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as index4HSbo4OAtCMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_939qifGCs0DOMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersYLQ4N9ikvvMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93IxQBgAQ1RQMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexAvvlEuOPdBMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93qOVKnVOgmuMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasDotWyr16CGMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesMVYv3m59d6Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cities.vue?macro=true";
import { default as indexyy9GmEXAvFMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/index.vue?macro=true";
import { default as indexmo1c9gIopEMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as _91movieSlug_934rBIQVekxZMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexMxOFR9tbN6Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93UHLjkJ5JdeMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresMT7b26EeHmMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexLoxHQElheoMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/index.vue?macro=true";
import { default as index9xsZh9Jn67Meta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93OT8Of0pUgoMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsn4fEvniGUiMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/persons.vue?macro=true";
import { default as searchZCNrLcpeVGMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/search.vue?macro=true";
import { default as voucherswVipgMu8BbMeta } from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.name ?? "pageSlug___en",
    path: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.path ?? "/en/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93evc6HKhu5EMeta || {},
    alias: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.name ?? "pageSlug___de",
    path: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.path ?? "/de/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93evc6HKhu5EMeta || {},
    alias: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.name ?? "pageSlug___es",
    path: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.path ?? "/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93evc6HKhu5EMeta || {},
    alias: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93evc6HKhu5EMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: activateRcAZKJZK6HMeta?.name ?? "account-activate___en",
    path: activateRcAZKJZK6HMeta?.path ?? "/en/account/activate",
    meta: activateRcAZKJZK6HMeta || {},
    alias: activateRcAZKJZK6HMeta?.alias || [],
    redirect: activateRcAZKJZK6HMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activateRcAZKJZK6HMeta?.name ?? "account-activate___de",
    path: activateRcAZKJZK6HMeta?.path ?? "/de/konto/aktivieren",
    meta: activateRcAZKJZK6HMeta || {},
    alias: activateRcAZKJZK6HMeta?.alias || [],
    redirect: activateRcAZKJZK6HMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activateRcAZKJZK6HMeta?.name ?? "account-activate___es",
    path: activateRcAZKJZK6HMeta?.path ?? "/cuenta/activar",
    meta: activateRcAZKJZK6HMeta || {},
    alias: activateRcAZKJZK6HMeta?.alias || [],
    redirect: activateRcAZKJZK6HMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: index0xTmVL2jcDMeta?.name ?? "account___en",
    path: index0xTmVL2jcDMeta?.path ?? "/en/account",
    meta: index0xTmVL2jcDMeta || {},
    alias: index0xTmVL2jcDMeta?.alias || [],
    redirect: index0xTmVL2jcDMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index0xTmVL2jcDMeta?.name ?? "account___de",
    path: index0xTmVL2jcDMeta?.path ?? "/de/konto",
    meta: index0xTmVL2jcDMeta || {},
    alias: index0xTmVL2jcDMeta?.alias || [],
    redirect: index0xTmVL2jcDMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index0xTmVL2jcDMeta?.name ?? "account___es",
    path: index0xTmVL2jcDMeta?.path ?? "/cuenta",
    meta: index0xTmVL2jcDMeta || {},
    alias: index0xTmVL2jcDMeta?.alias || [],
    redirect: index0xTmVL2jcDMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93F4Mvt8FqE9Meta?.name ?? "account-order-orderNumber-securityKey___en",
    path: _91securityKey_93F4Mvt8FqE9Meta?.path ?? "/en/account/order/:orderNumber/:securityKey",
    meta: _91securityKey_93F4Mvt8FqE9Meta || {},
    alias: _91securityKey_93F4Mvt8FqE9Meta?.alias || [],
    redirect: _91securityKey_93F4Mvt8FqE9Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93F4Mvt8FqE9Meta?.name ?? "account-order-orderNumber-securityKey___de",
    path: _91securityKey_93F4Mvt8FqE9Meta?.path ?? "/de/konto/bestellung/:orderNumber/:securityKey",
    meta: _91securityKey_93F4Mvt8FqE9Meta || {},
    alias: _91securityKey_93F4Mvt8FqE9Meta?.alias || [],
    redirect: _91securityKey_93F4Mvt8FqE9Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93F4Mvt8FqE9Meta?.name ?? "account-order-orderNumber-securityKey___es",
    path: _91securityKey_93F4Mvt8FqE9Meta?.path ?? "/cuenta/compra/:orderNumber/:securityKey",
    meta: _91securityKey_93F4Mvt8FqE9Meta || {},
    alias: _91securityKey_93F4Mvt8FqE9Meta?.alias || [],
    redirect: _91securityKey_93F4Mvt8FqE9Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: newDV7gy5FGAqMeta?.name ?? "account-password-new___en",
    path: newDV7gy5FGAqMeta?.path ?? "/en/account/password/new",
    meta: newDV7gy5FGAqMeta || {},
    alias: newDV7gy5FGAqMeta?.alias || [],
    redirect: newDV7gy5FGAqMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newDV7gy5FGAqMeta?.name ?? "account-password-new___de",
    path: newDV7gy5FGAqMeta?.path ?? "/de/konto/passwort/neu",
    meta: newDV7gy5FGAqMeta || {},
    alias: newDV7gy5FGAqMeta?.alias || [],
    redirect: newDV7gy5FGAqMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newDV7gy5FGAqMeta?.name ?? "account-password-new___es",
    path: newDV7gy5FGAqMeta?.path ?? "/cuenta/contrasena/nueva",
    meta: newDV7gy5FGAqMeta || {},
    alias: newDV7gy5FGAqMeta?.alias || [],
    redirect: newDV7gy5FGAqMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: watchlistlL87ITAot5Meta?.name ?? "account-watchlist___en",
    path: watchlistlL87ITAot5Meta?.path ?? "/en/account/watchlist",
    meta: watchlistlL87ITAot5Meta || {},
    alias: watchlistlL87ITAot5Meta?.alias || [],
    redirect: watchlistlL87ITAot5Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlistlL87ITAot5Meta?.name ?? "account-watchlist___de",
    path: watchlistlL87ITAot5Meta?.path ?? "/de/konto/merkliste",
    meta: watchlistlL87ITAot5Meta || {},
    alias: watchlistlL87ITAot5Meta?.alias || [],
    redirect: watchlistlL87ITAot5Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlistlL87ITAot5Meta?.name ?? "account-watchlist___es",
    path: watchlistlL87ITAot5Meta?.path ?? "/cuenta/marcadores",
    meta: watchlistlL87ITAot5Meta || {},
    alias: watchlistlL87ITAot5Meta?.alias || [],
    redirect: watchlistlL87ITAot5Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    path: _91citySlug_93qOVKnVOgmuMeta?.path ?? "/en/cinema/:citySlug",
    children: [
  {
    path: _91cinemaSlug_93IxQBgAQ1RQMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug",
    children: [
  {
    name: faqoBv6HeBWq4Meta?.name ?? "cinema-citySlug-cinemaSlug-faq___en",
    path: faqoBv6HeBWq4Meta?.path ?? "faq",
    meta: faqoBv6HeBWq4Meta || {},
    alias: faqoBv6HeBWq4Meta?.alias || [],
    redirect: faqoBv6HeBWq4Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexiJMztY87i8Meta?.name ?? "cinema-citySlug-cinemaSlug___en",
    path: indexiJMztY87i8Meta?.path ?? "",
    meta: indexiJMztY87i8Meta || {},
    alias: indexiJMztY87i8Meta?.alias || [],
    redirect: indexiJMztY87i8Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: info3o03BKc7RJMeta?.name ?? "cinema-citySlug-cinemaSlug-info___en",
    path: info3o03BKc7RJMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/info",
    meta: info3o03BKc7RJMeta || {},
    alias: info3o03BKc7RJMeta?.alias || [],
    redirect: info3o03BKc7RJMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    path: _91showGroupSlug_93jjP2lej3WDMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    children: [
  {
    name: indexWbdlRkKif7Meta?.name ?? "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: indexWbdlRkKif7Meta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    meta: indexWbdlRkKif7Meta || {},
    alias: indexWbdlRkKif7Meta?.alias || [],
    redirect: indexWbdlRkKif7Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showGroupSlug_93jjP2lej3WDMeta?.name ?? undefined,
    meta: _91showGroupSlug_93jjP2lej3WDMeta || {},
    alias: _91showGroupSlug_93jjP2lej3WDMeta?.alias || [],
    redirect: _91showGroupSlug_93jjP2lej3WDMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: programqOlwHDIozvMeta?.name ?? "cinema-citySlug-cinemaSlug-program___en",
    path: programqOlwHDIozvMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/program",
    meta: programqOlwHDIozvMeta || {},
    alias: programqOlwHDIozvMeta?.alias || [],
    redirect: programqOlwHDIozvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: shoptspmF1Bci0Meta?.name ?? "cinema-citySlug-cinemaSlug-shop___en",
    path: shoptspmF1Bci0Meta?.path ?? "shop",
    meta: shoptspmF1Bci0Meta || {},
    alias: shoptspmF1Bci0Meta?.alias || [],
    redirect: shoptspmF1Bci0Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_939qifGCs0DOMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    children: [
  {
    name: _91showName_93TW9GltLUY0Meta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: _91showName_93TW9GltLUY0Meta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    meta: _91showName_93TW9GltLUY0Meta || {},
    alias: _91showName_93TW9GltLUY0Meta?.alias || [],
    redirect: _91showName_93TW9GltLUY0Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: index4HSbo4OAtCMeta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: index4HSbo4OAtCMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    meta: index4HSbo4OAtCMeta || {},
    alias: index4HSbo4OAtCMeta?.alias || [],
    redirect: index4HSbo4OAtCMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_939qifGCs0DOMeta?.name ?? undefined,
    meta: _91showSlug_939qifGCs0DOMeta || {},
    alias: _91showSlug_939qifGCs0DOMeta?.alias || [],
    redirect: _91showSlug_939qifGCs0DOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m)
  },
  {
    name: vouchersYLQ4N9ikvvMeta?.name ?? "cinema-citySlug-cinemaSlug-vouchers___en",
    path: vouchersYLQ4N9ikvvMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    meta: vouchersYLQ4N9ikvvMeta || {},
    alias: vouchersYLQ4N9ikvvMeta?.alias || [],
    redirect: vouchersYLQ4N9ikvvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
],
    name: _91cinemaSlug_93IxQBgAQ1RQMeta?.name ?? undefined,
    meta: _91cinemaSlug_93IxQBgAQ1RQMeta || {},
    alias: _91cinemaSlug_93IxQBgAQ1RQMeta?.alias || [],
    redirect: _91cinemaSlug_93IxQBgAQ1RQMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m)
  },
  {
    name: indexAvvlEuOPdBMeta?.name ?? "cinema-citySlug___en",
    path: indexAvvlEuOPdBMeta?.path ?? "",
    meta: indexAvvlEuOPdBMeta || {},
    alias: indexAvvlEuOPdBMeta?.alias || [],
    redirect: indexAvvlEuOPdBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91citySlug_93qOVKnVOgmuMeta?.name ?? undefined,
    meta: _91citySlug_93qOVKnVOgmuMeta || {},
    alias: _91citySlug_93qOVKnVOgmuMeta?.alias || [],
    redirect: _91citySlug_93qOVKnVOgmuMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m)
  },
  {
    path: _91citySlug_93qOVKnVOgmuMeta?.path ?? "/de/kino/:citySlug",
    children: [
  {
    path: _91cinemaSlug_93IxQBgAQ1RQMeta?.path ?? "/de/kino/:citySlug/:cinemaSlug",
    children: [
  {
    name: faqoBv6HeBWq4Meta?.name ?? "cinema-citySlug-cinemaSlug-faq___de",
    path: faqoBv6HeBWq4Meta?.path ?? "faq",
    meta: faqoBv6HeBWq4Meta || {},
    alias: faqoBv6HeBWq4Meta?.alias || [],
    redirect: faqoBv6HeBWq4Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexiJMztY87i8Meta?.name ?? "cinema-citySlug-cinemaSlug___de",
    path: indexiJMztY87i8Meta?.path ?? "",
    meta: indexiJMztY87i8Meta || {},
    alias: indexiJMztY87i8Meta?.alias || [],
    redirect: indexiJMztY87i8Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: info3o03BKc7RJMeta?.name ?? "cinema-citySlug-cinemaSlug-info___de",
    path: info3o03BKc7RJMeta?.path ?? "/de/kino/:citySlug/:cinemaSlug/info",
    meta: info3o03BKc7RJMeta || {},
    alias: info3o03BKc7RJMeta?.alias || [],
    redirect: info3o03BKc7RJMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    path: _91showGroupSlug_93jjP2lej3WDMeta?.path ?? "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    children: [
  {
    name: indexWbdlRkKif7Meta?.name ?? "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: indexWbdlRkKif7Meta?.path ?? "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    meta: indexWbdlRkKif7Meta || {},
    alias: indexWbdlRkKif7Meta?.alias || [],
    redirect: indexWbdlRkKif7Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showGroupSlug_93jjP2lej3WDMeta?.name ?? undefined,
    meta: _91showGroupSlug_93jjP2lej3WDMeta || {},
    alias: _91showGroupSlug_93jjP2lej3WDMeta?.alias || [],
    redirect: _91showGroupSlug_93jjP2lej3WDMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: programqOlwHDIozvMeta?.name ?? "cinema-citySlug-cinemaSlug-program___de",
    path: programqOlwHDIozvMeta?.path ?? "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    meta: programqOlwHDIozvMeta || {},
    alias: programqOlwHDIozvMeta?.alias || [],
    redirect: programqOlwHDIozvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: shoptspmF1Bci0Meta?.name ?? "cinema-citySlug-cinemaSlug-shop___de",
    path: shoptspmF1Bci0Meta?.path ?? "shop",
    meta: shoptspmF1Bci0Meta || {},
    alias: shoptspmF1Bci0Meta?.alias || [],
    redirect: shoptspmF1Bci0Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_939qifGCs0DOMeta?.path ?? "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    children: [
  {
    name: _91showName_93TW9GltLUY0Meta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: _91showName_93TW9GltLUY0Meta?.path ?? "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    meta: _91showName_93TW9GltLUY0Meta || {},
    alias: _91showName_93TW9GltLUY0Meta?.alias || [],
    redirect: _91showName_93TW9GltLUY0Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: index4HSbo4OAtCMeta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: index4HSbo4OAtCMeta?.path ?? "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    meta: index4HSbo4OAtCMeta || {},
    alias: index4HSbo4OAtCMeta?.alias || [],
    redirect: index4HSbo4OAtCMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_939qifGCs0DOMeta?.name ?? undefined,
    meta: _91showSlug_939qifGCs0DOMeta || {},
    alias: _91showSlug_939qifGCs0DOMeta?.alias || [],
    redirect: _91showSlug_939qifGCs0DOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m)
  },
  {
    name: vouchersYLQ4N9ikvvMeta?.name ?? "cinema-citySlug-cinemaSlug-vouchers___de",
    path: vouchersYLQ4N9ikvvMeta?.path ?? "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    meta: vouchersYLQ4N9ikvvMeta || {},
    alias: vouchersYLQ4N9ikvvMeta?.alias || [],
    redirect: vouchersYLQ4N9ikvvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
],
    name: _91cinemaSlug_93IxQBgAQ1RQMeta?.name ?? undefined,
    meta: _91cinemaSlug_93IxQBgAQ1RQMeta || {},
    alias: _91cinemaSlug_93IxQBgAQ1RQMeta?.alias || [],
    redirect: _91cinemaSlug_93IxQBgAQ1RQMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m)
  },
  {
    name: indexAvvlEuOPdBMeta?.name ?? "cinema-citySlug___de",
    path: indexAvvlEuOPdBMeta?.path ?? "",
    meta: indexAvvlEuOPdBMeta || {},
    alias: indexAvvlEuOPdBMeta?.alias || [],
    redirect: indexAvvlEuOPdBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91citySlug_93qOVKnVOgmuMeta?.name ?? undefined,
    meta: _91citySlug_93qOVKnVOgmuMeta || {},
    alias: _91citySlug_93qOVKnVOgmuMeta?.alias || [],
    redirect: _91citySlug_93qOVKnVOgmuMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m)
  },
  {
    path: _91citySlug_93qOVKnVOgmuMeta?.path ?? "/cine/:citySlug",
    children: [
  {
    path: _91cinemaSlug_93IxQBgAQ1RQMeta?.path ?? "/cine/:citySlug/:cinemaSlug",
    children: [
  {
    name: faqoBv6HeBWq4Meta?.name ?? "cinema-citySlug-cinemaSlug-faq___es",
    path: faqoBv6HeBWq4Meta?.path ?? "faq",
    meta: faqoBv6HeBWq4Meta || {},
    alias: faqoBv6HeBWq4Meta?.alias || [],
    redirect: faqoBv6HeBWq4Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexiJMztY87i8Meta?.name ?? "cinema-citySlug-cinemaSlug___es",
    path: indexiJMztY87i8Meta?.path ?? "",
    meta: indexiJMztY87i8Meta || {},
    alias: indexiJMztY87i8Meta?.alias || [],
    redirect: indexiJMztY87i8Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: info3o03BKc7RJMeta?.name ?? "cinema-citySlug-cinemaSlug-info___es",
    path: info3o03BKc7RJMeta?.path ?? "/cine/:citySlug/:cinemaSlug/informacion",
    meta: info3o03BKc7RJMeta || {},
    alias: info3o03BKc7RJMeta?.alias || [],
    redirect: info3o03BKc7RJMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    path: _91showGroupSlug_93jjP2lej3WDMeta?.path ?? "movie/:showGroupSlug()",
    children: [
  {
    name: indexWbdlRkKif7Meta?.name ?? "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: indexWbdlRkKif7Meta?.path ?? "",
    meta: indexWbdlRkKif7Meta || {},
    alias: indexWbdlRkKif7Meta?.alias || [],
    redirect: indexWbdlRkKif7Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showGroupSlug_93jjP2lej3WDMeta?.name ?? undefined,
    meta: _91showGroupSlug_93jjP2lej3WDMeta || {},
    alias: _91showGroupSlug_93jjP2lej3WDMeta?.alias || [],
    redirect: _91showGroupSlug_93jjP2lej3WDMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: programqOlwHDIozvMeta?.name ?? "cinema-citySlug-cinemaSlug-program___es",
    path: programqOlwHDIozvMeta?.path ?? "/cine/:citySlug/:cinemaSlug/sesiones",
    meta: programqOlwHDIozvMeta || {},
    alias: programqOlwHDIozvMeta?.alias || [],
    redirect: programqOlwHDIozvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: shoptspmF1Bci0Meta?.name ?? "cinema-citySlug-cinemaSlug-shop___es",
    path: shoptspmF1Bci0Meta?.path ?? "shop",
    meta: shoptspmF1Bci0Meta || {},
    alias: shoptspmF1Bci0Meta?.alias || [],
    redirect: shoptspmF1Bci0Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_939qifGCs0DOMeta?.path ?? "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    children: [
  {
    name: _91showName_93TW9GltLUY0Meta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: _91showName_93TW9GltLUY0Meta?.path ?? "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    meta: _91showName_93TW9GltLUY0Meta || {},
    alias: _91showName_93TW9GltLUY0Meta?.alias || [],
    redirect: _91showName_93TW9GltLUY0Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: index4HSbo4OAtCMeta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: index4HSbo4OAtCMeta?.path ?? "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    meta: index4HSbo4OAtCMeta || {},
    alias: index4HSbo4OAtCMeta?.alias || [],
    redirect: index4HSbo4OAtCMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_939qifGCs0DOMeta?.name ?? undefined,
    meta: _91showSlug_939qifGCs0DOMeta || {},
    alias: _91showSlug_939qifGCs0DOMeta?.alias || [],
    redirect: _91showSlug_939qifGCs0DOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m)
  },
  {
    name: vouchersYLQ4N9ikvvMeta?.name ?? "cinema-citySlug-cinemaSlug-vouchers___es",
    path: vouchersYLQ4N9ikvvMeta?.path ?? "/cine/:citySlug/:cinemaSlug/codigos",
    meta: vouchersYLQ4N9ikvvMeta || {},
    alias: vouchersYLQ4N9ikvvMeta?.alias || [],
    redirect: vouchersYLQ4N9ikvvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
],
    name: _91cinemaSlug_93IxQBgAQ1RQMeta?.name ?? undefined,
    meta: _91cinemaSlug_93IxQBgAQ1RQMeta || {},
    alias: _91cinemaSlug_93IxQBgAQ1RQMeta?.alias || [],
    redirect: _91cinemaSlug_93IxQBgAQ1RQMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m)
  },
  {
    name: indexAvvlEuOPdBMeta?.name ?? "cinema-citySlug___es",
    path: indexAvvlEuOPdBMeta?.path ?? "",
    meta: indexAvvlEuOPdBMeta || {},
    alias: indexAvvlEuOPdBMeta?.alias || [],
    redirect: indexAvvlEuOPdBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91citySlug_93qOVKnVOgmuMeta?.name ?? undefined,
    meta: _91citySlug_93qOVKnVOgmuMeta || {},
    alias: _91citySlug_93qOVKnVOgmuMeta?.alias || [],
    redirect: _91citySlug_93qOVKnVOgmuMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m)
  },
  {
    name: cinemasDotWyr16CGMeta?.name ?? "cinemas___en",
    path: cinemasDotWyr16CGMeta?.path ?? "/en/cinemas",
    meta: cinemasDotWyr16CGMeta || {},
    alias: cinemasDotWyr16CGMeta?.alias || [],
    redirect: cinemasDotWyr16CGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: cinemasDotWyr16CGMeta?.name ?? "cinemas___de",
    path: cinemasDotWyr16CGMeta?.path ?? "/de/kinos",
    meta: cinemasDotWyr16CGMeta || {},
    alias: cinemasDotWyr16CGMeta?.alias || [],
    redirect: cinemasDotWyr16CGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: cinemasDotWyr16CGMeta?.name ?? "cinemas___es",
    path: cinemasDotWyr16CGMeta?.path ?? "/cines",
    meta: cinemasDotWyr16CGMeta || {},
    alias: cinemasDotWyr16CGMeta?.alias || [],
    redirect: cinemasDotWyr16CGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: citiesMVYv3m59d6Meta?.name ?? "cities___en",
    path: citiesMVYv3m59d6Meta?.path ?? "/en/cities",
    meta: citiesMVYv3m59d6Meta || {},
    alias: citiesMVYv3m59d6Meta?.alias || [],
    redirect: citiesMVYv3m59d6Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesMVYv3m59d6Meta?.name ?? "cities___de",
    path: citiesMVYv3m59d6Meta?.path ?? "/de/staedte",
    meta: citiesMVYv3m59d6Meta || {},
    alias: citiesMVYv3m59d6Meta?.alias || [],
    redirect: citiesMVYv3m59d6Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesMVYv3m59d6Meta?.name ?? "cities___es",
    path: citiesMVYv3m59d6Meta?.path ?? "/ciudades",
    meta: citiesMVYv3m59d6Meta || {},
    alias: citiesMVYv3m59d6Meta?.alias || [],
    redirect: citiesMVYv3m59d6Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: indexyy9GmEXAvFMeta?.name ?? "index___en",
    path: indexyy9GmEXAvFMeta?.path ?? "/en",
    meta: indexyy9GmEXAvFMeta || {},
    alias: indexyy9GmEXAvFMeta?.alias || [],
    redirect: indexyy9GmEXAvFMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexyy9GmEXAvFMeta?.name ?? "index___de",
    path: indexyy9GmEXAvFMeta?.path ?? "/de",
    meta: indexyy9GmEXAvFMeta || {},
    alias: indexyy9GmEXAvFMeta?.alias || [],
    redirect: indexyy9GmEXAvFMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexyy9GmEXAvFMeta?.name ?? "index___es",
    path: indexyy9GmEXAvFMeta?.path ?? "/",
    meta: indexyy9GmEXAvFMeta || {},
    alias: indexyy9GmEXAvFMeta?.alias || [],
    redirect: indexyy9GmEXAvFMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_934rBIQVekxZMeta?.path ?? "/en/movie/:movieSlug",
    children: [
  {
    name: indexmo1c9gIopEMeta?.name ?? "movie-movieSlug___en",
    path: indexmo1c9gIopEMeta?.path ?? "",
    meta: indexmo1c9gIopEMeta || {},
    alias: indexmo1c9gIopEMeta?.alias || [],
    redirect: indexmo1c9gIopEMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_934rBIQVekxZMeta?.name ?? undefined,
    meta: _91movieSlug_934rBIQVekxZMeta || {},
    alias: _91movieSlug_934rBIQVekxZMeta?.alias || [],
    redirect: _91movieSlug_934rBIQVekxZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_934rBIQVekxZMeta?.path ?? "/de/film/:movieSlug",
    children: [
  {
    name: indexmo1c9gIopEMeta?.name ?? "movie-movieSlug___de",
    path: indexmo1c9gIopEMeta?.path ?? "",
    meta: indexmo1c9gIopEMeta || {},
    alias: indexmo1c9gIopEMeta?.alias || [],
    redirect: indexmo1c9gIopEMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_934rBIQVekxZMeta?.name ?? undefined,
    meta: _91movieSlug_934rBIQVekxZMeta || {},
    alias: _91movieSlug_934rBIQVekxZMeta?.alias || [],
    redirect: _91movieSlug_934rBIQVekxZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_934rBIQVekxZMeta?.path ?? "/pelicula/:movieSlug",
    children: [
  {
    name: indexmo1c9gIopEMeta?.name ?? "movie-movieSlug___es",
    path: indexmo1c9gIopEMeta?.path ?? "",
    meta: indexmo1c9gIopEMeta || {},
    alias: indexmo1c9gIopEMeta?.alias || [],
    redirect: indexmo1c9gIopEMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_934rBIQVekxZMeta?.name ?? undefined,
    meta: _91movieSlug_934rBIQVekxZMeta || {},
    alias: _91movieSlug_934rBIQVekxZMeta?.alias || [],
    redirect: _91movieSlug_934rBIQVekxZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91genreSlug_93UHLjkJ5JdeMeta?.path ?? "/en/movies/genre/:genreSlug",
    children: [
  {
    name: indexMxOFR9tbN6Meta?.name ?? "movies-genre-genreSlug___en",
    path: indexMxOFR9tbN6Meta?.path ?? "",
    meta: indexMxOFR9tbN6Meta || {},
    alias: indexMxOFR9tbN6Meta?.alias || [],
    redirect: indexMxOFR9tbN6Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91genreSlug_93UHLjkJ5JdeMeta?.name ?? undefined,
    meta: _91genreSlug_93UHLjkJ5JdeMeta || {},
    alias: _91genreSlug_93UHLjkJ5JdeMeta?.alias || [],
    redirect: _91genreSlug_93UHLjkJ5JdeMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m)
  },
  {
    path: _91genreSlug_93UHLjkJ5JdeMeta?.path ?? "/de/filme/genre/:genreSlug",
    children: [
  {
    name: indexMxOFR9tbN6Meta?.name ?? "movies-genre-genreSlug___de",
    path: indexMxOFR9tbN6Meta?.path ?? "",
    meta: indexMxOFR9tbN6Meta || {},
    alias: indexMxOFR9tbN6Meta?.alias || [],
    redirect: indexMxOFR9tbN6Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91genreSlug_93UHLjkJ5JdeMeta?.name ?? undefined,
    meta: _91genreSlug_93UHLjkJ5JdeMeta || {},
    alias: _91genreSlug_93UHLjkJ5JdeMeta?.alias || [],
    redirect: _91genreSlug_93UHLjkJ5JdeMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m)
  },
  {
    path: _91genreSlug_93UHLjkJ5JdeMeta?.path ?? "/peliculas/generos/:genreSlug",
    children: [
  {
    name: indexMxOFR9tbN6Meta?.name ?? "movies-genre-genreSlug___es",
    path: indexMxOFR9tbN6Meta?.path ?? "",
    meta: indexMxOFR9tbN6Meta || {},
    alias: indexMxOFR9tbN6Meta?.alias || [],
    redirect: indexMxOFR9tbN6Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91genreSlug_93UHLjkJ5JdeMeta?.name ?? undefined,
    meta: _91genreSlug_93UHLjkJ5JdeMeta || {},
    alias: _91genreSlug_93UHLjkJ5JdeMeta?.alias || [],
    redirect: _91genreSlug_93UHLjkJ5JdeMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m)
  },
  {
    name: genresMT7b26EeHmMeta?.name ?? "movies-genres___en",
    path: genresMT7b26EeHmMeta?.path ?? "/en/movies/genres",
    meta: genresMT7b26EeHmMeta || {},
    alias: genresMT7b26EeHmMeta?.alias || [],
    redirect: genresMT7b26EeHmMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: genresMT7b26EeHmMeta?.name ?? "movies-genres___de",
    path: genresMT7b26EeHmMeta?.path ?? "/de/filme/genres",
    meta: genresMT7b26EeHmMeta || {},
    alias: genresMT7b26EeHmMeta?.alias || [],
    redirect: genresMT7b26EeHmMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: genresMT7b26EeHmMeta?.name ?? "movies-genres___es",
    path: genresMT7b26EeHmMeta?.path ?? "/peliculas/generos",
    meta: genresMT7b26EeHmMeta || {},
    alias: genresMT7b26EeHmMeta?.alias || [],
    redirect: genresMT7b26EeHmMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: indexLoxHQElheoMeta?.name ?? "movies___en",
    path: indexLoxHQElheoMeta?.path ?? "/en/movies",
    meta: indexLoxHQElheoMeta || {},
    alias: indexLoxHQElheoMeta?.alias || [],
    redirect: indexLoxHQElheoMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoxHQElheoMeta?.name ?? "movies___de",
    path: indexLoxHQElheoMeta?.path ?? "/de/filme",
    meta: indexLoxHQElheoMeta || {},
    alias: indexLoxHQElheoMeta?.alias || [],
    redirect: indexLoxHQElheoMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoxHQElheoMeta?.name ?? "movies___es",
    path: indexLoxHQElheoMeta?.path ?? "/peliculas",
    meta: indexLoxHQElheoMeta || {},
    alias: indexLoxHQElheoMeta?.alias || [],
    redirect: indexLoxHQElheoMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    path: _91personSlug_93OT8Of0pUgoMeta?.path ?? "/en/person/:personSlug",
    children: [
  {
    name: index9xsZh9Jn67Meta?.name ?? "person-personSlug___en",
    path: index9xsZh9Jn67Meta?.path ?? "",
    meta: index9xsZh9Jn67Meta || {},
    alias: index9xsZh9Jn67Meta?.alias || [],
    redirect: index9xsZh9Jn67Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91personSlug_93OT8Of0pUgoMeta?.name ?? undefined,
    meta: _91personSlug_93OT8Of0pUgoMeta || {},
    alias: _91personSlug_93OT8Of0pUgoMeta?.alias || [],
    redirect: _91personSlug_93OT8Of0pUgoMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    path: _91personSlug_93OT8Of0pUgoMeta?.path ?? "/de/person/:personSlug",
    children: [
  {
    name: index9xsZh9Jn67Meta?.name ?? "person-personSlug___de",
    path: index9xsZh9Jn67Meta?.path ?? "",
    meta: index9xsZh9Jn67Meta || {},
    alias: index9xsZh9Jn67Meta?.alias || [],
    redirect: index9xsZh9Jn67Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91personSlug_93OT8Of0pUgoMeta?.name ?? undefined,
    meta: _91personSlug_93OT8Of0pUgoMeta || {},
    alias: _91personSlug_93OT8Of0pUgoMeta?.alias || [],
    redirect: _91personSlug_93OT8Of0pUgoMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    path: _91personSlug_93OT8Of0pUgoMeta?.path ?? "/persona/:personSlug",
    children: [
  {
    name: index9xsZh9Jn67Meta?.name ?? "person-personSlug___es",
    path: index9xsZh9Jn67Meta?.path ?? "",
    meta: index9xsZh9Jn67Meta || {},
    alias: index9xsZh9Jn67Meta?.alias || [],
    redirect: index9xsZh9Jn67Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91personSlug_93OT8Of0pUgoMeta?.name ?? undefined,
    meta: _91personSlug_93OT8Of0pUgoMeta || {},
    alias: _91personSlug_93OT8Of0pUgoMeta?.alias || [],
    redirect: _91personSlug_93OT8Of0pUgoMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: personsn4fEvniGUiMeta?.name ?? "persons___en",
    path: personsn4fEvniGUiMeta?.path ?? "/en/persons",
    meta: personsn4fEvniGUiMeta || {},
    alias: personsn4fEvniGUiMeta?.alias || [],
    redirect: personsn4fEvniGUiMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: personsn4fEvniGUiMeta?.name ?? "persons___de",
    path: personsn4fEvniGUiMeta?.path ?? "/de/personen",
    meta: personsn4fEvniGUiMeta || {},
    alias: personsn4fEvniGUiMeta?.alias || [],
    redirect: personsn4fEvniGUiMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: personsn4fEvniGUiMeta?.name ?? "persons___es",
    path: personsn4fEvniGUiMeta?.path ?? "/personas",
    meta: personsn4fEvniGUiMeta || {},
    alias: personsn4fEvniGUiMeta?.alias || [],
    redirect: personsn4fEvniGUiMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: searchZCNrLcpeVGMeta?.name ?? "search___en",
    path: searchZCNrLcpeVGMeta?.path ?? "/en/search",
    meta: searchZCNrLcpeVGMeta || {},
    alias: searchZCNrLcpeVGMeta?.alias || [],
    redirect: searchZCNrLcpeVGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchZCNrLcpeVGMeta?.name ?? "search___de",
    path: searchZCNrLcpeVGMeta?.path ?? "/de/suche",
    meta: searchZCNrLcpeVGMeta || {},
    alias: searchZCNrLcpeVGMeta?.alias || [],
    redirect: searchZCNrLcpeVGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchZCNrLcpeVGMeta?.name ?? "search___es",
    path: searchZCNrLcpeVGMeta?.path ?? "/buscar",
    meta: searchZCNrLcpeVGMeta || {},
    alias: searchZCNrLcpeVGMeta?.alias || [],
    redirect: searchZCNrLcpeVGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: voucherswVipgMu8BbMeta?.name ?? "vouchers___en",
    path: voucherswVipgMu8BbMeta?.path ?? "/en/vouchers",
    meta: voucherswVipgMu8BbMeta || {},
    alias: voucherswVipgMu8BbMeta?.alias || [],
    redirect: voucherswVipgMu8BbMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: voucherswVipgMu8BbMeta?.name ?? "vouchers___de",
    path: voucherswVipgMu8BbMeta?.path ?? "/de/gutscheine",
    meta: voucherswVipgMu8BbMeta || {},
    alias: voucherswVipgMu8BbMeta?.alias || [],
    redirect: voucherswVipgMu8BbMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: voucherswVipgMu8BbMeta?.name ?? "vouchers___es",
    path: voucherswVipgMu8BbMeta?.path ?? "/codigos",
    meta: voucherswVipgMu8BbMeta || {},
    alias: voucherswVipgMu8BbMeta?.alias || [],
    redirect: voucherswVipgMu8BbMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]