<template>
  <ModalContent
    ref="modalRef"
    :open="true"
    :show-close-button="true"
    :title="show?.cinema?.name"
    :show-overlay="true"
    :classes="{
      outer: 'w-full h-full sm:p-8 sm:h-auto !max-w-[1020px]',
      modal:
        'sm:rounded-lg grid grid-rows-[auto,minmax(0,1fr),auto] bg-light text-dark dark:bg-neutral-dark-2 dark:text-light drop-shadow-2xl sm:max-h-[90dvh] h-full sm:h-auto max-w-[1020px]',
      content:
        'sm:min-w-[600px] w-full h-full sm:h-auto max-w-[1020px] sm:px-5 overflow-y-auto scrollbar-themed',
      footer: '!hidden',
    }"
    @close="emit('close')"
  >
    <template #default>
      <LegacyWidget :options="legacyWidgetOptions" />
    </template>
  </ModalContent>
</template>

<script lang="ts" setup>
import type { Show } from '#gql/default'

interface Props {
  show: Show
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

const cmsConfig = useCmsConfig()
const runtimeConfig = useRuntimeConfig()

const legacyWidgetOptions = computed(() => {
  const { urlSlug, cinema } = props.show

  if (!urlSlug || !cinema) {
    return null
  }

  return {
    baseUrl: runtimeConfig.public.WIDGET_BASE_URL,
    cmsConfigId: cmsConfig.value?.id,
    cinemaPath: `kino/${cinema.city.urlSlug}/${cinema.urlSlug}/show/${urlSlug}`,
    // TODO: make widget design customizable via CMS
    design: 'sitefactory-saw',
    showId: urlSlug,
    rb: false,
    noChange: 1,
  }
})

defineOptions({
  name: 'ShowBookingModal',
})
</script>
