<template>
  <Teleport to="body">
    <div :class="cssClasses.container" @click.self="close">
      <div :class="cssClasses.dropdown" class="w-full">
        <div :class="cssClasses.content">
          <slot name="default" />
        </div>
        <div v-if="slots.footer" :class="cssClasses.footer">
          <slot name="footer" />
        </div>
      </div>

      <div :class="cssClasses.backdrop" @click.self="close" />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const slots = useSlots()
const emit = defineEmits(['close'])

const { open, close, cssClasses } = useDropdown({
  classes: {
    dropdown: 'delay-200',
  },
  style: DROPDOWN_STYLE.OFF_CANVAS,
  onClose: () => emit('close'),
})

onMounted(async () => {
  await nextTick()
  open()
})

defineOptions({
  name: 'DropdownContentOffCanvas',
})
</script>
