<template>
  <div
    v-if="logo"
    class="hidden md:block md:h-6 [&>svg]:h-full [&>svg]:w-auto"
    v-html="logo"
  />
</template>

<script lang="ts" setup>
const cmsConfig = useCmsConfig()
const logo = computed(() => cmsConfig.value?.consentSettings.logo ?? null)

defineOptions({
  name: 'ConsentManagerLogo',
})
</script>
