<template>
  <Teleport to="body">
    <div :class="cssClasses.container">
      <div
        :class="cssClasses.modal"
        class="bg-light dark:bg-dark text-dark dark:text-light relative px-5 py-4 sm:rounded-lg sm:px-8"
      >
        <div class="grid grid-cols-3 place-items-center gap-x-5 gap-y-3">
          <h2
            class="col-span-3 place-self-start font-bold md:col-span-2"
            v-html="settings?.title"
          />
          <ConsentManagerLogo class="place-self-end" />

          <ExpandableText
            class="col-span-3 md:col-span-2"
            :text="settings?.description!"
          />

          <div
            class="col-span-3 grid w-full gap-2 md:col-span-1 md:flex md:flex-col"
            :class="{
              'grid-cols-1 flex-col sm:grid-cols-3 sm:flex-row sm:gap-x-3':
                buttons.length === 3,
              'grid-cols-2': buttons.length === 2,
            }"
          >
            <Button
              v-if="buttons?.includes(CONSENT_MANAGER_BUTTONS.ACCEPT_ALL)"
              :theme="BUTTON_THEME.PRIMARY"
              :size="BUTTON_SIZE.SM"
              :title="t('btn.accept')"
              :text="t('btn.accept')"
              @click="acceptAll(), close()"
            />
            <Button
              v-if="buttons.includes(CONSENT_MANAGER_BUTTONS.CUSTOMIZE)"
              :theme="BUTTON_THEME.PRIMARY"
              :size="BUTTON_SIZE.SM"
              :title="t('btn.customize')"
              :text="t('btn.customize')"
              @click="declineAll(), (settingsModal = true), close()"
            />
            <Button
              v-if="buttons.includes(CONSENT_MANAGER_BUTTONS.DENY_ALL)"
              :theme="BUTTON_THEME.PRIMARY"
              :size="BUTTON_SIZE.SM"
              :title="t('btn.deny')"
              :text="t('btn.deny')"
              @click="declineAll(), close()"
            />
          </div>

          <ConsentManagerLinks :links="settings?.links" />
        </div>
      </div>
      <div :class="cssClasses.backdrop" />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const cmsConfig = useCmsConfig()
const settings = computed(() => cmsConfig.value?.consentSettings.initialModal)
const buttons = computed(() => settings.value?.buttons as string[])

const { t } = useI18n()
const { acceptAll, declineAll, userConsent, toggleModal } = useConsentManager()
const settingsModal = ref(false)

const { close, cssClasses } = useModal({
  initiallyOpened: true,
  classes: {
    container:
      'fixed inset-0 z-50 flex flex-col items-end justify-end sm:items-center sm:p-10',
  },
  onClose: () => {
    toggleModal({ settings: settingsModal.value, initial: false })
  },
})

watch(userConsent, () => userConsent.value && close(), { immediate: true })

defineOptions({
  name: 'ConsentManagerInitialModal',
})
</script>

<i18n>
de:
  btn:
    accept: "Alle akzeptieren"
    deny: "Alle ablehnen"
    customize: "Einstellungen"
es:
  btn:
    accept: "Aceptar todo"
    deny: "Rechazar todo"
    customize: "Configuraciones"
</i18n>
