<template>
  <div
    :id
    class="flex justify-center empty:hidden [&>div]:inline-flex"
    :class="{
      'hidden sm:flex': slotName === 'skin',
    }"
  />
</template>

<script lang="ts" setup>
const getRouteBaseName = useRouteBaseName()
const route = useRoute()

interface Props {
  slotName: string
  publisherId: string
  id: string
  name: string
  sizes: Array<Array<number>>
}

const props = defineProps<Props>()

function loadAdSlot() {
  googletag.cmd.push(() => {
    const mapping = googletag
      .sizeMapping()
      .addSize(
        [1024, 768] as googletag.SingleSizeArray,
        [
          [970, 250],
          [970, 90],
          [728, 90],
          [1, 1],
        ] as googletag.SingleSize[]
      )
      .addSize(
        [970, 250] as googletag.SingleSizeArray,
        [
          [970, 250],
          [970, 90],
          [728, 90],
        ] as googletag.SingleSize[]
      )
      .addSize(
        [728, 90] as googletag.SingleSizeArray,
        [[728, 90]] as googletag.SingleSize[]
      )
      .addSize(
        [320, 50] as googletag.SingleSizeArray,
        [
          [320, 50],
          [320, 100],
          [320, 480],
        ] as googletag.SingleSize[]
      )
      .addSize(
        [0, 0] as googletag.SingleSizeArray,
        [] as googletag.SingleSize[]
      )
      .build()

    googletag
      .pubads()
      .setTargeting('demoAds', route.query.demoAds ? 'true' : 'false')
      .setTargeting('content_group', getRouteBaseName(route) as string)

    googletag
      .defineSlot(`/${props.publisherId}/${props.name}`, props.sizes, props.id)
      ?.addService(googletag.pubads())
      .defineSizeMapping(mapping || [])

    googletag.enableServices()
    googletag.display(props.id)
  })
}

onMounted(loadAdSlot)

defineOptions({
  name: 'AdSlot',
})
</script>
